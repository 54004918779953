export default {
  name: 'dark',

  /* Template */
  token: {
    borderRadius: 6,

    colorMenuPrimaryText: '255 255 255',
    colorMenuPrimaryBg: '35 48 68',
    colorMenuSecondaryBg: '29 42 56',
    colorHeaderBg: '29 42 56',

    // AntDesign
    colorText: 'rgba(255, 255, 255, 0.85)',
    colorTextSecondary: 'rgba(255, 255, 255, 0.65)',
    colorTextTertiary: 'rgba(255, 255, 255, 0.45)',
    colorTextQuaternary: 'rgba(255, 255, 255, 0.25)',

    colorBorder: '#48658d',
    colorBorderSecondary: '#3c5475',

    colorFill: 'rgba(255, 255, 255, 0.18)',
    colorFillSecondary: 'rgba(255, 255, 255, 0.12)',
    colorFillTertiary: 'rgba(255, 255, 255, 0.08)',
    colorFillQuaternary: 'rgba(255, 255, 255, 0.04)',

    colorBgContainer: '#293950',
    colorBgElevated: '#30435e',
    colorBgLayout: '#1b2635',
    colorBgSpotlight: '#48658d',
    colorBgMask: 'rgba(0, 0, 0, 0.45)',
    colorBgCard: '#394c66',

    colorPrimary: '#1668dc',
    colorPrimaryHover: '#3c89e8',
    colorPrimaryActive: '#1554ad',
    colorPrimaryBg: '#111a2c',
    colorPrimaryBgHover: '#112545',
    colorPrimaryBorder: '#15325b',
    colorPrimaryBorderHover: '#15417e',
    colorPrimaryText: '#1668dc',
    colorPrimaryTextHover: '#3c89e8',
    colorPrimaryTextActive: '#1554ad',
  },
  components: {
    Button: {
      controlOutline: 'rgba(0, 0, 0, 0.05)',
    },
  },
}
