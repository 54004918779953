import {
  createContext, useState,
  useEffect,
  useContext,
} from 'react'
import PropTypes from 'prop-types'

import Api from 'services/api'
import { NotificationContext } from 'app/contexts/Notifications'

export const AgoraContext = createContext(null)

const Agora = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [community, setCommunity] = useState({})
  const [communities, setCommunities] = useState({ me: [], others: [], shouldUpdatePosts: false })
  const [notified, setNotified] = useState([])

  const [postParams, setPostParams] = useState({
    $populate: [
      { path: '_sender', select: 'firstName lastName picture' },
      {
        path: '_communities',
        select: 'name shortName _users _admins categories',
        populate: [{
          path: '_admins', select: 'firstName lastName',
        },
        { path: '_users', select: 'firstName lastName' },
        { path: 'categories._files' }],
      },
      { path: 'reactions._users', select: 'firstName lastName' },
      {
        path: '_survey',
        populate: [{
          path: 'answers._users',
          select: 'firstName lastName',
        }],
      },
      {
        path: '_comments',
        populate: [{
          path: '_sender',
          select: 'firstName lastName picture',
        }, {
          path: 'reactions._users',
          select: 'firstName lastName',
        }, {
          path: '_comments',
          populate: [{
            path: '_sender',
            select: 'firstName lastName picture',
          }, {
            path: 'reactions._users',
            select: 'firstName lastName',
          }],
        }],
      },
      {
        path: '_event',
        populate: {
          path: '_attendees',
          select: 'firstName lastName picture',
        },
      },
      {
        path: '_files',
      },
    ],
    $sort: '-_createdAt',
    $pagination: { itemsPerPage: 10 },
  })

  const communityParams = {
    $populate: [
      { path: 'categories._files' },
    ],
  }
  const getCommunity = async (communityId) => {
    const { community: data } = await Api.axios.get(`/v4/admin/communities/${communityId}`, { params: communityParams })
    setCommunity(data)
  }

  const { notification } = useContext(NotificationContext)

  useEffect(() => {
    if (notification?.type === 'agora-messages') {
      setNotified(notification?.communities || [])
    }
  }, [notification])

  return (
    <AgoraContext.Provider value={{
      isLoading,
      setIsLoading,
      community,
      setCommunity,
      notified,
      setNotified,
      communities,
      setCommunities,
      postParams,
      setPostParams,
      getCommunity,
    }}>
      {children}
    </AgoraContext.Provider>
  )
}

Agora.propTypes = {
  children: PropTypes.any,
}

export default Agora
