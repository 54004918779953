import {
  createContext, useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import socketIOClient from 'socket.io-client'

import parseJSON from 'utils/parseJSON'
import { JWT_TOKEN } from 'app/config'

export const NotificationContext = createContext(null)

const messageHandler = (message, setNotification) => {
  setNotification(parseJSON(message))
}

const NotificationContexGeneral = ({ children }) => {
  const [notification, setNotification] = useState(null)
  const [firstOpenning, setFirstOpenning] = useState(false)
  const { isAuthenticated, user } = useSelector((state) => ({
    isAuthenticated: state.Auth?.isAuthenticated || false,
    user: state.Auth.user || {},
  }))

  useEffect(() => {
    let socket = null
    if (isAuthenticated && !firstOpenning) {
      const adminToken = localStorage.getItem(JWT_TOKEN)
      let url = `https://ws.uptoo.${process.env.API_URL === 'https://api.uptoo.fr' ? 'fr' : 'dev'}`
      if (process.env.API_URL?.includes('localhost')) url = 'http://localhost:3001'
      socket = socketIOClient(url, { auth: { token: adminToken }})
      socket.on('message', (message) => messageHandler(message, setNotification))
      const room = `notifications-user-${user?._id}`
      socket.emit('ready', room)
      setFirstOpenning(true)
    }
    return () => {
      if (socket) {
        socket.off('message', (message) => messageHandler(message, setNotification))
        socket.disconnect()
      }
    }
  }, [isAuthenticated])

  return (
    <NotificationContext.Provider value={{
      notification, setNotification,
    }}>
      {children}
    </NotificationContext.Provider>
  )
}

NotificationContexGeneral.propTypes = {
  children: PropTypes.any,
}

export default NotificationContexGeneral
