import axios from 'axios'
import jwtDecode from 'jwt-decode'

import { JWT_TOKEN } from 'app/config'
import { getUserData } from './actions'

import Store from './index'

export const checkAuthentication = (from = null) => {
  // get admin JWT
  const adminToken = localStorage.getItem(JWT_TOKEN)

  if (adminToken) {
    // decode token
    const decodedToken = jwtDecode(adminToken)
    // check validity
    if (decodedToken.exp * 1000 < Date.now()) {
      // Store.dispatch(logoutUser(from))
      localStorage.removeItem(JWT_TOKEN)
      Store.dispatch({ type: 'SET_UNAUTHENTICATED', payload: from })
    } else {
      // Init session
      axios.defaults.headers.common.Authorization = `Bearer: ${adminToken}`
      Store.dispatch(getUserData(from))
    }
  } else {
    Store.dispatch({ type: 'SET_UNAUTHENTICATED', payload: from })
  }
}

export default checkAuthentication
