import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  // Input, Button,
  Form, Spin,
} from 'antd'

import OTP from 'components/fromChatGpt/OTP'

const { Item } = Form

const Authenticator = ({ loading, action }) => {
  const [form] = Form.useForm()
  const [code, setCode] = useState()

  useEffect(() => {
    if (code?.length === 6) {
      action({ code })
    }
  }, [code])

  // const handleSubmit = async () => {
  //   try {
  //     const values = await form.validateFields()
  //     action(values)
  //   } finally {}
  // }

  return (
    <Spin spinning={loading}>
      <Form form={form}>
        {/* <h3 className="text-center">Double authentification</h3> */}
        <div className="flex items-center justify-center mb-10">
          <img src="https://uptoo.fr/assets/galaxy/Google_Authenticator_for_Android_icon.svg.png" className="w-[70px] h-[70px]" />
        </div>
        <Item name='code'>
          {/* <Input
            size="large"
            prefix={<LockOutlined />}
            placeholder="Code de sécurité"
            autoComplete="off"
            autoFocus
            onPressEnter={handleSubmit}
          /> */}
          <OTP
            value={code}
            onChange={setCode}
          />
        </Item>
        {/* <Item>
          <Button type="primary" onClick={() => handleSubmit()} loading={loading}>
            Connexion
          </Button>
        </Item> */}
      </Form>
    </Spin>
  )
}

Authenticator.propTypes = {
  action: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default Authenticator
