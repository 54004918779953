import { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import enUS from 'antd/locale/en_US'
import frFR from 'antd/locale/fr_FR'

import { I18N_LANGUAGE, THEME_TOKEN } from 'app/config'

export const AppContext = createContext(null)

const antdLocales = {
  fr: frFR,
  en: enUS,
}

const AppContextWrapper = ({ applications, children }) => {
  // Langue
  const locale = localStorage.getItem(I18N_LANGUAGE)
  const [antdLocale, setAntdLocale] = useState(antdLocales[locale] || 'fr')

  const updateAntdLocale = (code) => {
    if (antdLocales[code]) {
      setAntdLocale(antdLocales[code])
    }
  }

  // Theme
  const localTheme = localStorage.getItem(THEME_TOKEN) || 'default'
  const [theme, setTheme] = useState(localTheme)

  return (
    <AppContext.Provider value={{
      applications, antdLocale, updateAntdLocale, theme, setTheme,
    }}>
      {children}
    </AppContext.Provider>
  )
}

AppContextWrapper.propTypes = {
  applications: PropTypes.any,
  children: PropTypes.any,
}

export default AppContextWrapper
