import {
  createContext, useState,
  useEffect,
  useContext,
} from 'react'
import PropTypes from 'prop-types'

import { NotificationContext as NotificationContexGeneral } from 'app/contexts/Notifications'

export const NotificationContext = createContext(null)

const NotificationContextAlert = ({ children }) => {
  const [alerts, setAlerts] = useState([])
  const { notification } = useContext(NotificationContexGeneral)

  useEffect(() => {
    if (notification?.type === 'alert-writing' || notification?.type === 'alert-transcription') {
      setAlerts(notification)
    }
  }, [notification])

  return (
    <NotificationContext.Provider value={{
      alerts, setAlerts,
    }}>
      {children}
    </NotificationContext.Provider>
  )
}

NotificationContextAlert.propTypes = {
  children: PropTypes.any,
}

export default NotificationContextAlert
