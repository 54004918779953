import {
  createContext, useState,
  useEffect,
  useContext,
} from 'react'
import PropTypes from 'prop-types'

import { NotificationContext as NotificationContexGeneral } from 'app/contexts/Notifications'

export const NotificationContext = createContext(null)

const NotificationContextExportKpi = ({ children }) => {
  const [fileUrl, setFileUrl] = useState()
  const { notification } = useContext(NotificationContexGeneral)

  useEffect(() => {
    if (notification?.type === 'extract-kpi') {
      setFileUrl(notification.fileUrl)
    }
  }, [notification])

  return (
    <NotificationContext.Provider value={{
      fileUrl,
    }}>
      {children}
    </NotificationContext.Provider>
  )
}

NotificationContextExportKpi.propTypes = {
  children: PropTypes.any,
}

export default NotificationContextExportKpi
