import {
  createContext, useState,
  useEffect,
  useContext,
} from 'react'
import PropTypes from 'prop-types'

import { NotificationContext as NotificationContexGeneral } from 'app/contexts/Notifications'

export const NotificationContext = createContext(null)

const NotificationContextFeed = ({ children }) => {
  const [feedNotifications, setFeedNotifications] = useState({})
  const [feedVisible, setFeedVisible] = useState(false)
  const { notification } = useContext(NotificationContexGeneral)

  useEffect(() => {
    if (notification?.type === 'message-feed') {
      setFeedNotifications(notification)
    }
  }, [notification])

  return (
    <NotificationContext.Provider value={{
      feedNotifications, setFeedNotifications, feedVisible, setFeedVisible,
    }}>
      {children}
    </NotificationContext.Provider>
  )
}

NotificationContextFeed.propTypes = {
  children: PropTypes.any,
}

export default NotificationContextFeed
