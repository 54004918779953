import { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { ConfigProvider, message, notification } from 'antd'

import { AppContext } from 'app/contexts/AppContextWrapper'
import Layout from 'app/layout'
import themes from 'src/themes'

import 'css/tailwind.css'
import 'css/galaxy.css'
import 'css/antd.css'

// https://ant.design/docs/react/customize-theme => Liste des Tokens
// https://ant.design/theme-editor => Détails des components

const Theme = ({ children }) => {
  // Récupération des informations depuis le contexte
  const { antdLocale, theme } = useContext(AppContext)

  // Récupération du theme
  const currentTheme = themes.find((t) => t.name === theme) || themes[0]

  // Configuration de Ant Design
  message.config({
    top: 11,
    duration: 2,
  })

  notification.config({
    placement: 'topRight',
    bottom: 0,
    duration: 3,
  })

  // Application des couleurs lors du changement du theme
  useEffect(() => {
    const entries = Object.entries(currentTheme.token)

    entries.forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value)
    })
  }, [currentTheme])

  return (
    <ConfigProvider locale={antdLocale} theme={currentTheme}>
      <Layout>
        {children}
      </Layout>
    </ConfigProvider>
  )
}

Theme.propTypes = {
  children: PropTypes.object.isRequired,
}

export default Theme
