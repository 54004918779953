import { Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import minMax from 'dayjs/plugin/minMax'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import Login from 'components/auth'
import Alert from 'app/contexts/Alert'
import ExtractKpi from 'app/contexts/ExtractKpi'
import AppContextWrapper from 'app/contexts/AppContextWrapper'
import Notifications from 'app/contexts/Notifications'
import Agora from 'app/contexts/Agora'
import Feed from 'app/contexts/Feed'
import Theme from 'app/Theme'
import GuestRoute from 'app/routes/GuestRoute'
import PrivateRoute from 'app/routes/PrivateRoute'
import { checkAuthentication } from 'store/middlewares'
import applications from 'apps'
// Init Dayjs

import 'dayjs/locale/fr'

import 'react-grid-layout/css/styles.css'
// import 'react-resizable/css/styles.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
})

export const App = ({ store }) => {
  dayjs.extend(isBetween)
  dayjs.extend(minMax)
  dayjs.extend(isSameOrBefore)
  dayjs.extend(isSameOrAfter)
  dayjs.extend(relativeTime)
  dayjs.extend(utc)
  dayjs.extend(duration)
  dayjs.extend(localizedFormat)
  dayjs.locale('fr')

  useEffect(() => {
    checkAuthentication()
    // console.log({ socket })
    // socket.on('')
  }, [])

  return (
    // todo: remplacer par spinner propre
    <Suspense fallback='loading'>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <AppContextWrapper applications={applications}>
            <Notifications>
              <Feed>
                <Alert>
                  <Agora>
                    < ExtractKpi>
                      <Router>
                        <Theme>
                          <Switch>
                            <GuestRoute
                              exact
                              path='/auth'
                              component={Login}
                            />
                            {applications.map((app) => (
                              <PrivateRoute
                                key={app.key}
                                path={app.to}
                                component={app.component}
                                only={app.only}
                                except={app.except}
                              />
                            ))}
                            <Redirect from="/consulting/audits/:id" to="/change/scans/:id" />
                            <Redirect from='/' to='/agora' />
                          </Switch>
                        </Theme>
                      </Router>
                    </ExtractKpi>
                  </Agora>
                </Alert>
              </Feed>
            </Notifications>
          </AppContextWrapper>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Suspense>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired,
}

export default App
