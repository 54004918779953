import { useEffect, useState } from 'react'
import PropTypes, { oneOfType } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { notification, Spin } from 'antd'

import { resetRedirect } from 'store/actions'

const PrivateRoute = ({
  component: Component, only, except, extraProps, ...rest
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [access, setAccess] = useState(true)
  const [accessLoading, setAccessLoading] = useState(true)
  const [lastAuthorizedPath, setLastAuthorizedPath] = useState(null)

  const Auth = useSelector((state) => ({
    isAuthenticated: state.Auth.isAuthenticated,
    isLoading: state.Auth.isLoading,
    permissions: state.Auth.user?.permissions || [],
    scopes: state.Auth.user?.scopes || [],
    pathname: state.router?.location?.pathname,
    forceRedirect: state.Auth?.forceRedirect,
    redirect: state.Auth?.redirect,
  }))

  const defaultAuthenticatedRedirect = '/agora'

  useEffect(() => {
    // Only check access if authenticated
    if (Auth.isAuthenticated) {
      if (
        // Check access to current route
        (!except || !except.some((scope) => Auth.scopes.includes(scope)))
        && (!only || only.some((scope) => Auth.scopes.includes(scope)))
        && Auth.scopes?.length
      ) {
        // Save current path for hypothetical future redirect, then grant access
        setLastAuthorizedPath(rest.location?.pathname)
        setAccess(true)
      } else {
        // Deny access
        notification.error({ message: 'Accès interdit' })
        setAccess(false)
      }
      setAccessLoading(false)
    } else if (!Auth.isLoading) {
      setAccessLoading(false)
    }
  }, [Component, Auth.pathname, Auth.isAuthenticated, Auth.isLoading])

  useEffect(() => {
    if (Auth.forceRedirect && Auth.redirect) {
      const to = Auth.redirect
      dispatch(resetRedirect())
      history.push(to)
    }
  }, [Auth.forceRedirect, Auth.redirect])

  const renderRoute = (props) => {
    if (accessLoading) {
      return (
        <div style={{
          height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>
          <Spin />
        </div>
      )
    }
    if (!Auth.isAuthenticated) {
      return (
        <Redirect to={{ pathname: '/auth', state: { from: props.location }}} />
      )
    }
    if (!access) {
      return (
        <Redirect to={{ pathname: lastAuthorizedPath || defaultAuthenticatedRedirect }} />
      )
    }
    return (
      <Component {...props} {...extraProps} />
    )
  }

  return (
    <Route
      {...rest}
      render={renderRoute}
    />
  )
}

PrivateRoute.propTypes = {
  component: oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  extraProps: PropTypes.object,
  only: PropTypes.array,
  except: PropTypes.array,
  location: PropTypes.object,
}

export default PrivateRoute
