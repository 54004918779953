import { useEffect, useContext } from 'react'
import { Switch } from 'antd'

import useOnlyChangeEffect from 'helpers/useOnlyChangeEffect'
import { AppContext } from 'app/contexts/AppContextWrapper'

export default function DarkMode() {
  const { theme, setTheme } = useContext(AppContext)

  const lightTheme = 'light'
  const darkTheme = 'dark'

  useOnlyChangeEffect(() => {
    document.querySelector('html').setAttribute('data-theme', theme)
    localStorage.setItem('theme', theme)
  }, [theme])

  useEffect(() => {
    if (localStorage) {
      const t = localStorage.getItem('theme')

      if (t) {
        return setTheme(t)
      }
    }

    const dark
      = window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches

    if (dark) {
      setTheme(darkTheme)
    } else {
      setTheme(lightTheme)
    }
    return true
  }, [])

  return (
    <Switch
      onChange={(e) => setTheme(e ? darkTheme : lightTheme)}
      checked={theme === 'dark'}
    />
  )
}
