import loadable from '@loadable/component'
import {
  AdjustmentsHorizontalIcon, BriefcaseIcon, ChartPieIcon, CogIcon,
  CreditCardIcon, DocumentTextIcon, BuildingLibraryIcon,
  PresentationChartLineIcon, ShieldCheckIcon, ShoppingCartIcon,
  SparklesIcon, UserPlusIcon, AcademicCapIcon, BoltIcon, MegaphoneIcon,
} from '@heroicons/react/24/solid'
import { BrainCircuit } from 'lucide-react'

const Agora = loadable(() => import('apps/agora'))
const SubMenuAgora = loadable(() => import('apps/agora/menu'))

const Accounting = loadable(() => import('apps/accounting'))
const SubmenuAccounting = loadable(() => import('apps/accounting/menu'))

const Change = loadable(() => import('apps/change'))
const SubmenuChange = loadable(() => import('apps/change/menu'))

const Recruitment = loadable(() => import('apps/recruitment'))
const SubmenuRecruitment = loadable(() => import('apps/recruitment/menu'))

const Training = loadable(() => import('apps/training'))
const SubmenuTraining = loadable(() => import('apps/training/menu'))

const Admin = loadable(() => import('apps/admin'))
const SubmenuAdmin = loadable(() => import('apps/admin/menu'))

const Assessment = loadable(() => import('apps/assessment'))
const SubmenuAssessment = loadable(() => import('apps/assessment/menu'))

const Coaching = loadable(() => import('apps/coaching'))
const SubmenuCoaching = loadable(() => import('apps/coaching/menu'))

const Sales = loadable(() => import('apps/sales'))
const SubmenuSales = loadable(() => import('apps/sales/menu'))

const Academy = loadable(() => import('apps/academy'))
const SubmenuAcademy = loadable(() => import('apps/academy/menu'))

const Account = loadable(() => import('apps/account'))
const SubmenuAccount = loadable(() => import('apps/account/menu'))

const HR = loadable(() => import('apps/hr'))
const SubmenuHR = loadable(() => import('apps/hr/menu'))

const Marketing = loadable(() => import('apps/marketing'))
const SubmenuMarketing = loadable(() => import('apps/marketing/menu'))

const Audit = loadable(() => import('apps/audit'))
const SubmenuAudit = loadable(() => import('apps/audit/menu'))

const Kpi = loadable(() => import('apps/kpi'))
const SubmenuKpi = loadable(() => import('apps/kpi/menu'))

// eslint-disable-next-line import/no-cycle
const Clients = loadable(() => import('apps/clients'))
const SubmenuClients = loadable(() => import('apps/clients/menu'))

const GPT = loadable(() => import('apps/gpt'))
const SubmenuGPT = loadable(() => import('apps/gpt/menu'))
/*
    Permissions warning:

    - The props 'only' and 'except' can be passed as props to the top level route in App.
    - It will determine which apps the current user can access, as well as which items are listed in the apps menu.
    - For both only and except, empty arrays are considered as valid. If no restriction is wanted, the prop should be omitted.
    - except always takes priority over only.

    Example:
    {
        admin: {
            only: ['DEV'],    // Only grant access to people with the 'DEV' permission
            except: ['admin']       // Deny access to anyone with the 'admin' permission, even if they have the 'DEV' permission
        }
    }
*/
export default [
  {
    title: 'Agora',
    to: '/agora',
    key: 'agora',
    icon: <SparklesIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Agora,
    submenu: <SubMenuAgora />,
  },
  {
    title: 'RH',
    to: '/hr',
    key: 'hr',
    icon: <ShieldCheckIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: HR,
    submenu: <SubmenuHR />,
  },
  {
    title: 'KPI',
    to: '/kpi',
    key: 'kpi',
    icon: <PresentationChartLineIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Kpi,
    submenu: <SubmenuKpi />,
  },
  {
    title: 'Recrutement',
    to: '/recruitment',
    key: 'recruitment',
    icon: <UserPlusIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Recruitment,
    submenu: <SubmenuRecruitment />,
  },
  {
    title: 'Formation',
    to: '/training',
    key: 'training',
    icon: <AcademicCapIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Training,
    submenu: <SubmenuTraining />,
  },
  {
    title: 'Conseil',
    to: '/change',
    key: 'change',
    icon: <AdjustmentsHorizontalIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Change,
    submenu: <SubmenuChange />,
  },
  {
    title: 'Audit',
    to: '/audit',
    key: 'audit',
    icon: <DocumentTextIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Audit,
    submenu: <SubmenuAudit />,
  },
  {
    title: 'Assessment',
    to: '/assessment',
    key: 'assessment',
    icon: <ChartPieIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Assessment,
    submenu: <SubmenuAssessment />,
  },
  {
    title: 'Coaching',
    to: '/coaching',
    key: 'coaching',
    icon: <MegaphoneIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Coaching,
    submenu: <SubmenuCoaching />,
  },
  {
    title: 'Commerce',
    to: '/sales',
    key: 'sales',
    icon: <BriefcaseIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Sales,
    submenu: <SubmenuSales />,
  },
  {
    title: 'Clients',
    to: '/clients',
    key: 'clients',
    icon: <ShoppingCartIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Clients,
    submenu: <SubmenuClients />,
  },
  {
    title: 'Facturation',
    to: '/accounting',
    key: 'accounting',
    icon: <CreditCardIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Accounting,
    submenu: <SubmenuAccounting />,
  },
  {
    title: 'Marketing',
    to: '/marketing',
    key: 'marketing',
    icon: <BoltIcon className="h-6 w-6" />,
    only: ['MARKETING'],
    component: Marketing,
    submenu: <SubmenuMarketing />,
  },
  {
    title: 'Academy',
    to: '/academy',
    key: 'academy',
    icon: <BuildingLibraryIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Academy,
    submenu: <SubmenuAcademy />,
  },
  {
    title: 'Administration',
    to: '/admin',
    key: 'admin',
    icon: <ShieldCheckIcon className="h-6 w-6" />,
    only: ['GALAXY'],
    component: Admin,
    submenu: <SubmenuAdmin />,
  },
  {
    title: 'Paramètres',
    to: '/account',
    key: 'account',
    icon: <CogIcon className="h-6 w-6" />,
    component: Account,
    submenu: <SubmenuAccount />,
    hidden: true,
  },
  {
    title: 'GalaxyAI',
    to: '/gpt',
    key: 'gpt',
    icon: <BrainCircuit className="h-6 w-6" />,
    only: ['DEV', 'MARKETING'],
    component: GPT,
    submenu: <SubmenuGPT />,
    disallowedHidden: true,
  },
]
