export default {
  name: 'default',

  // Template
  token: {
    borderRadius: 6,

    colorMenuPrimaryText: '255 255 255',
    colorMenuPrimaryBg: '35 48 68',
    colorMenuSecondaryBg: '29 42 56',
    // colorMenuPrimaryText: '0 0 0',
    // colorMenuPrimaryBg: '255 255 255',
    // colorMenuSecondaryBg: '240 240 240',
    colorHeaderBg: '255 255 255',

    // AntDesign
    colorText: 'rgba(0, 0, 0, 0.88)',
    colorTextSecondary: 'rgba(0, 0, 0, 0.65)',
    colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
    colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',

    colorBorder: '#d9d9d9',
    colorBorderSecondary: '#f0f0f0',

    colorFill: 'rgba(0, 0, 0, 0.15)',
    colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
    colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
    colorFillQuaternary: 'rgba(0, 0, 0, 0.02)',

    colorBgContainer: '#ffffff',
    colorBgElevated: '#ffffff',
    colorBgLayout: '#f5f5f5',
    colorBgSpotlight: 'rgba(0, 0, 0, 0.85)',
    colorBgMask: 'rgba(0, 0, 0, 0.45)',
    colorBgCard: '#fafafa',

    colorPrimary: '#1677ff',
    colorPrimaryBg: '#e6f4ff',
    colorPrimaryHover: '#4096ff',
    colorPrimaryActive: '#0958d9',
    colorPrimaryText: '#1677ff',
    colorPrimaryTextHover: '#4096ff',
    colorPrimaryTextActive: '#0958d9',
    colorPrimaryBgHover: '#bae0ff',
    colorPrimaryBorder: '#91caff',
    colorPrimaryBorderHover: '#69b1ff',
  },
}
