import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'

const OneTimePasswordInput = ({ count = 6, value = '', onChange }) => {
  const [otpValues, setOtpValues] = useState(value.split(''))
  const inputRefs = useRef([])

  useEffect(() => {
    inputRefs.current[0]?.focus()
  }, [])

  const handleChange = (e, index) => {
    const newOtpValues = [...otpValues]
    newOtpValues[index] = e.target.value
    setOtpValues(newOtpValues)

    if (onChange) {
      onChange(newOtpValues.join(''))
    }

    if (e.target.value && index < count - 1) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handlePaste = (e) => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData('Text').substr(0, count)
    const newOtpValues = [...otpValues]

    for (let i = 0; i < count; i += 1) {
      if (i < pastedData.length) {
        newOtpValues[i] = pastedData[i]
      } else {
        newOtpValues[i] = ''
      }
    }

    setOtpValues(newOtpValues)

    if (onChange) {
      onChange(newOtpValues.join(''))
    }

    inputRefs.current[0].focus()
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otpValues[index]) {
      e.preventDefault()
      const newOtpValues = [...otpValues]
      newOtpValues[index - 1] = ''
      setOtpValues(newOtpValues)

      if (onChange) {
        onChange(newOtpValues.join(''))
      }

      if (index > 0) {
        inputRefs.current[index - 1].focus()
      }
    }
  }

  const handleFocus = (e) => {
    e.target.select()
  }

  return (
    <div className="flex space-x-2 items-center justify-center">
      {[...Array(count)].map((_, index) => (
        <Input
          key={index}
          className="w-[40px] text-center h-[60px] text-2xl input-no-arrow"
          type='number'
          size="large"
          maxLength={1}
          value={otpValues[index]}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          onFocus={handleFocus}
          ref={(ref) => {
            inputRefs.current[index] = ref
          }}
          autoFocus={index === 0}
        />
      ))}
    </div>
  )
}

OneTimePasswordInput.propTypes = {
  count: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default OneTimePasswordInput
