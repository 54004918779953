import { createRoot } from 'react-dom/client'

import Store from './store/index'
import Application from 'app'

import 'app/i18n'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Application store={Store} />,
)
