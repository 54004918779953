import { useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { useLocation } from 'react-router-dom'

const Header = loadable(() => import('app/layout/header'))
const Mobile = loadable(() => import('app/layout/header/mobile'))
const Sidebar = loadable(() => import('app/layout/sidebar'))
const DrawerFeed = loadable(() => import('app/layout/feed'))

const Layout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false)
  const { pathname } = useLocation()

  return (
    pathname === '/auth'
      ? children
      : (
        <>
          <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
          <div
            id={collapsed ? 'main-collapsed' : 'main'}>
            <Header />
            <Mobile />
            <div id="content">
              {children}
            </div>
          </div>
          <DrawerFeed/>
        </>
      )
  )
}

Layout.propTypes = {
  children: PropTypes.any,
}

export default Layout
