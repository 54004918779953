import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import dayjs from 'dayjs'

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: {
      /* Translation file path */
      loadPath: '/locales/i18n/{{ns}}/{{lng}}.json',
    },
    supportedLngs: ['fr', 'en'],
    // supportedLngs: ['fr', 'en', 'dev']
    // fallbackLng: 'fr', // Keep as default 'dev' in development, set to 'fr' in production // env detection not working... cf webpack files
    // debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['common', 'glossary'], // Always loaded for both current language and fallbackLng
    defaultNS: 'common', // Can be accessed without specifying the namespace
    partialBundledLanguages: true,
    returnObjects: true,
    // react: {
    //   wait: true
    // }
  })

i18n.on('languageChanged', (lng) => {
  dayjs.locale(lng)
})

export default i18n
