import Cookies from 'js-cookie'

import Api from 'services/api'
import { JWT_TOKEN } from 'app/config'

export const loginUser = (userData, from = null) => async (dispatch) => {
  try {
    // Loading status
    dispatch({ type: 'LOADING_USER' })

    // Login to get $token
    const { authenticator } = await Api.axios.post('v4/auth/login', { ...userData, type: 'admin' })

    if (authenticator) {
      // Display 2FA
      dispatch({
        type: 'AUTH_AUTHENTICATOR',
        payload: authenticator,
      })
    } else {
      // Fetch user data
      dispatch(getUserData(from))
    }
  } catch (error) {
    dispatch({
      type: 'SET_UNAUTHENTICATED',
      payload: from,
    })
  }
}

export const authenticate = ({ email, authenticator, code }, from = null) => async (dispatch) => {
  try {
    await Api.axios.post('v4/auth/authenticate', { email, authenticator, code })
    dispatch(getUserData(from))
  } catch (error) {
    dispatch({
      type: 'SET_UNAUTHENTICATED',
      payload: from,
    })
  }
}

export const getUserData = (from = null) => async (dispatch) => {
  try {
    dispatch({ type: 'LOADING_USER' })

    const { user } = await Api.axios.get('v4/auth/me')
    dispatch({
      type: 'SET_USER',
      payload: user,
    })

    // Required to set redirect path
    dispatch({
      type: 'SET_AUTHENTICATED',
      payload: from,
    })
  } catch (err) {
    console.log(err)
  }
}

export const logoutUser = (from = null) => async (dispatch) => {
  try {
    localStorage.removeItem(JWT_TOKEN)
    // localStorage.removeItem('previousPath') // temporary clean-up for unused var, should be removed later

    await Api.axios.post('v4/auth/logout')

    delete Api.axios.defaults.headers.common.Authorization

    dispatch({
      type: 'SET_UNAUTHENTICATED',
      payload: from,
    })
  } catch (err) {
    console.log(err)
  }
}

export const resetRedirect = () => async (dispatch) => {
  try {
    dispatch({ type: 'RESET_REDIRECT' })
  } catch (err) {
    console.log(err)
  }
}
