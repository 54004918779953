import axios from 'axios'
import { API_DOMAIN, JWT_TOKEN } from 'app/config'

const $pagination = {
  $itemsPerPage: 25,
  $currentIndex: 1,
}

const requester = async (request, setData = null) => {
  const {
    $url,
    $method = 'GET',
    ...params
  } = request

  if (setData) {
    await setData((prevState) => ({
      $pagination,
      ...prevState,
      loading: true,
      error: null,
    }))
  }

  const token = localStorage.getItem(JWT_TOKEN)

  // Stringify arrays
  const paramsStringified = { ...params }

  Object.keys(paramsStringified).forEach((key) => {
    paramsStringified[key] = JSON.stringify(paramsStringified[key])
  })

  const data = {
    url: `${API_DOMAIN}${$url}`,
    headers: { authorization: token },
    params: $method === 'GET' ? paramsStringified : undefined,
    method: $method,
  }

  if ($method === 'PUT' || $method === 'POST') {
    const {
      $pagination, loading, error, success, ...otherParams
    } = params
    data.data = otherParams
    // data.data = params
  }

  try {
    const response = await axios(data)
    if (setData) {
      await setData((prevState) => ({
        ...prevState,
        ...response.data,
        success: true,
        loading: false,
        error: null,
      }))
    }
  } catch (error) {
    if (setData) {
      await setData((prevState) => ({
        $pagination,
        ...prevState,
        success: false,
        loading: false,
        error,
      }))
    }
  }
}

export default requester
